import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import NoCashback from '../../../../components/Empty/NoCashback';
import apiFactory from '../../../../services/apiFactory';
import { getLoggedInUser } from '../../../../store/actions/auth';
// svgs
import Svg_balance from '../../../../assets/svgs/profile/balance.svg';
import './index.css';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import LoadingSpinner from '../../../../components/Spinner';
import { identifyDevice, trackEvent } from '../../../../utils/mixpanelUtil';
import Theme from '../../../../assets/theme';
import WalletTransactionItem from '../../../../components/Order/WalletTransactionItem';
import moment from 'moment'

const PerPage = 10;

const Wallet = (props) => {
  const { t } = useTranslation();
  const _isMounted = useRef(true);

  const [loading, setLoading] = useState(null);
  const [cashbacks, setCashbacks] = useState([]);
  const [page, setCurPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    props.getLoggedInUser();
    loadCashback(1, PerPage, true);

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const loadCashback = (page, perPage, forceLoading = false) => {
    if (loading && forceLoading == false) {
      return;
    }
    setLoading(true);
    const params = [`page=${page}`, `per_page=${perPage}`];
    apiFactory.get(`wallet-transactions?${params.join('&')}`)
      .then(({ data }) => {
        identifyDevice();
        trackEvent('Load Cashback', {
          action_category: 'Load Screen',
          action_outcome: 'success',
          interaction_element: 'Screen',
          source: 'sf_web'
        });
        if (_isMounted.current !== true) {
          return;
        }

        if (page > 1) {
          const currentOrderIds = cashbacks.map((x) => x.id);
          const newItems = data.data.filter((x) => currentOrderIds.indexOf(x.id) === -1);
          setCurPage(data['current_page']);
          setTotalPages(data['last_page']);
          setCashbacks([...cashbacks, ...newItems])
        } else {
          setCurPage(data['current_page']);
          setTotalPages(data['last_page']);
          setCashbacks(data.data || []);
        }
        setLoading(false);
      },
        () => {
          identifyDevice();
          trackEvent('Cashback', {
            action_category: 'Retrieve Cashback',
            action_outcome: 'fail',
            interaction_element: 'Screen',
            source: 'sf_web'
          });
          if (_isMounted.current === true) {
            setLoading(false);
          }
        });
  }

  const _renderBalance = () => {
    return (
      <div className={'align-middle balance'}>
        <img width={40} src={Svg_balance} />
        <div
          className={'align-col-middle flex_1'}
          style={{ marginLeft: 10, alignItems: 'flex-start' }}>
          <div className={'balance-txt'}>{t('wallet.your_balance')}</div>
          <div className={'balance-desc'}>{t('wallet.your_balance_description')}</div>
        </div>
        <div className={'align-middle'} style={{ alignItems: 'flex-end', marginLeft: 12 }}>
          <div className={'balance-price'}>{props.user.cashback_amount || 0}</div>
          <div className={'balance-unit'}>L</div>
        </div>
      </div>
    );
  };

  const renderDate = (prevItem, curItem) => {
    let prev_date = '';
    let cur_date = '';
    let display_date = '';
    if (prevItem) {
      prev_date = moment(prevItem.created_at, "YYYY-MM-DD hh:mm:ss").format("YYYY-MM-DD");
    }
    if (curItem) {
      cur_date = moment(curItem.created_at, "YYYY-MM-DD hh:mm:ss").format("YYYY-MM-DD");
    }

    if (prev_date != cur_date) {
      display_date = cur_date;
      if (moment().format("YYYY-MM-DD") == display_date) {
        display_date = t('Today');
      }
    }

    if (display_date == '') { return null }
    return (
      <div style={{ textAlign: 'center', marginBottom: 12, fontSize: 14, fontFamily: Theme.fonts.medium, color: Theme.colors.gray7 }}>
        {display_date}
      </div>
    )
  }

  return (
    <div className={'align-start wallet-screen'}>
      <div className={'scrollview'}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (page < totalPages) {
              loadCashback(page + 1, PerPage);
            }
          }}
          hasMore={page < totalPages}
          loader={
            <div className="align-col-middle mt12 mb20" key={0}>
              <CircularProgress size={20} color="primary" />
            </div>
          }
          useWindow={true}>
          {_renderBalance()}
          <div className={'divider'} />
          <div className={'subject-title'}>{t('wallet.cashback_history')}</div>
          {loading === true ? (
            <LoadingSpinner />
          ) : loading === false && cashbacks.length === 0 ? (
            <NoCashback />
          ) : (
            cashbacks.map((item, index) =>
              <>
                {renderDate((index == 0 ? null : cashbacks[index - 1]), cashbacks[index])}
                <WalletTransactionItem
                  key={index}
                  data={item}
                  style={{ width: '100%', marginBottom: 12, }}
                />
              </>
            )
          )}
          <div style={{ height: 40 }} />
        </InfiniteScroll>
      </div>
    </div>
  );
};

Wallet.propTypes = {
  user: {
    full_name: PropTypes.string,
    photo: PropTypes.string,
    user_category: PropTypes.shape({
      name: PropTypes.string
    }),
    cashback_amount: PropTypes.number
  },
  getLoggedInUser: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {}
});

export default connect(mapStateToProps, { getLoggedInUser })(Wallet);
